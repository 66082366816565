export const faqData = [
    {
        section: 'LE CONCEPT -  L\'ABONNEMENT',
        contents: [
            {
                question: 'Comment fonctionne CABADICI ?',
                answer: `CABADICI est un service de sélection de produits locaux, de proposition de recettes de saison et de livraison d'ingrédients prêts à cuisiner.
                Nous vous livrons tous les ingrédients nécessaires à la réalisation de nos recettes (produits frais, produits secs, épices, légumes...).
                Ces ingrédients sont calibrés pour 4 portions adultes et sont issus d'une production locale (Sud de la France essentiellement) et/ou biologique et/ou acheminée en circuit court.
                Chaque semaine, nous vous proposons de retrouver 4 nouvelles recettes sur notre site internet. Une recette supplémentaire vous sera également proposée chaque mois, elle restera commandable pendant 4 semaines.
                Vous pouvez donc choisir quelles recettes vous souhaitez réaliser (2 à 5 recettes) et vous faire livrer le vendredi votre CABADICI personnalisé.
                Chaque recette est garantie facilement réalisable, sans niveau de cuisine ou équipement particulier.
                Le petit plus : vous pouvez choisir de vous abonner pour recevoir votre CABADICI sans y penser.
                Rien d'obligatoire cependant, notre abonnement, modifiable chaque semaine et sans engagement (à partir de 2 commandes), est flexible : si vous souhaitez ne recevoir qu'un CABADICI pour essayer, c'est possible!`
            },
            {
                question: 'Et l\'abonnement, il marche comment ?',
                answer: `Dans un premier temps, en passant votre première commande, vous créez votre compte personnel et choisissez si vous souhaitez souscrire à notre abonnement.
                Une fois votre inscription terminée, nous prenons donc note de vous prévoir un CABADICI chaque semaine.
                Il vous appartiendra d'aller, une fois par semaine, avant le dimanche minuit, modifier vos choix pour votre livraison de la semaine suivante.
                Ainsi, en vous connectant dans votre espace personnel, vous pourrez choisir
                - de modifier le nombre de recettes que vous souhaitez recevoir dans votre prochain CABADICI
                - quelles recettes vous souhaitez cuisiner
                - de modifier vos modalités de livraison
                - de suspendre les livraisons pour la ou les prochaine(s) semaine(s)
                - ou même de résilier votre abonnement
                Mais, choisir de s'abonner implique aussi que si vous ne faites rien, vous serez livré tous les vendredis d'un CABADICI qui reprendra votre dernier choix :
                - le même nombre de recettes
                - le même type de recette (végétarienne, viande, poisson...)
                - les mêmes produits d'épicerie en complément
                - la même adresse de livraison (ou le même point relais ou le même groupement de livraison si celui est disponible)
                - la même plage horaire de livraison`
            },
            {
                question: 'Si je m\'abonne, je suis prélevé toutes les semaines ?',
                answer: `En pratique, si vous vous abonnez :
                1- vous commandez votre premier CABADICI à recevoir le vendredi d'après (peu importe quand vous le commandez, vous êtes débités sur le moment)
                2 - avant même d'avoir reçu votre première livraison, nous préparons la commande suivante : vous êtes donc prévenus par mail le mardi de ne pas oublier d'aller choisir vos prochaines recettes avant le dimanche minuit sans quoi nous choisirons pour vous
                3- le vendredi, vous recevez votre premier CABADICI
                4 - lors de la livraison, nous vous rappelons de vous connecter sur votre espace avant minuit pour choisir vos recettes ou suspendre vos livraisons
                5- si après ce délai, vous n'avez pas modifié vos préférences, un CABADICI reprenant vos précédents choix sera prévu pour vous pour la semaine suivante
                6- vous êtes prévenus par mail le samedi de votre prochaine livraison
                7- vous êtes débités dans la nuit du dimanche au lundi du même montant que votre précédente commande`
            },
            {
                question: 'Je n\'ai pas envie de m\'abonner, je préfère être libre',
                answer: `Vous souhaitez essayer sans vous abonner ? Vous ne savez pas si le concept est fait pour vous ou si les produits vous conviendront ?
                Aucun problème, lorsque vous démarrez votre inscription, choisissez l'option sans abonnement.
                Le tarif de votre CABADICI sera augmenté forfaitairement de 5€ TTC.
                En effet, notre proposition d'abonnement vous permet de bénéficier d'un tarif préférentiel, que nous ne sommes pas encore en mesure d'appliquer aux paniers en commande unique.
                Vos codes de carte de paiment ne seront pas enregistrés et il s'agira d'un prélèvement unique, qui sera débité sur votre compte 7 jours avant la livraison prévue.
                Cependant, si vous pensez pouvoir tester 2 commandes, choisissez l'option abonnement.
                Vous bénéficierez alors du tarif général préférentiel, et pourrez suspendre ou résilier votre abonnement juste après.
                Sans engagement de durée après deux commandes (pas forcément consécutives), cet abonnement vous permet simplement de recevoir chaque semaine un CABADICI avec des rappels hebdomadaires le mardi par mail et le vendredi au moment de votre livraison.
                Vous restez donc libres de ne plus recevoir de CABADICI, tant que nous en sommes prévenus 7 jours avant.`
            },
            {
                question: 'Je souhaite résilier mon abonnement, comment faire ?',
                answer: `Si vous souhaitez ne plus recevoir de CABADICI vous pouvez choisir (une fois connecté à votre espace personnel) :
                - soit de suspendre vos 3 prochaines livraisons (mais il faudra penser à revenir régulièrement suspendre les livraisons suivantes, car pour le moment nous ne pouvons techniquement vous donner la possibilité d'accéeder à votre calendrier sur plus de 3 semaines)
                - soit de résilier votre abonnement ce qui vous permet de ne pas avoir à revenir pour suspendre vos livraisons au delà de 3 semaines.
                (dans votre espace, onglet ""mes livraisons"", bouton ""suspendre mon abonnement"")
                Dans ce cas là, et même si nous sommes désolées de vous voir partir, vous ne recevrez plus de CABADICI.
                ATTENTION, les suspensions ou résiliations doivent se faire avant le dimanche minuit, sans quoi un CABADICI sera automatiquement prévu pour vous.
                Une fois votre abonnement résilié, si vous souhaitez reprendre vos livraisons, il vous suffira de vous reconnecter à votre espace personnel et de simplement cliquer sur ""reprendre mon abonnement"".`
            }
        ]
    },
    {
        section: 'LES LIVRAISONS',
        contents: [
            {
                question: 'Jusqu\'où pouvons-nous nous faire livrer ?',
                answer: `Nous vous livrons partout en France métropolitaine grâce à Chronofresh, la branche FOOD de la Poste.
                Cela dit, nous vous livrerons en priorité nous-même, si vous êtes dans notre secteur (Montpellier et ses alentours) et tant que notre planning nous le permet.
                Nous avons à coeur de venir nous-même à votre rencontre, de pouvoir discuter de vos choix, de vos envies, de rester proches de vous!
                Pour autant, si vous souhaitez profiter d'un CABADICI pendant les vacances ou envoyer un peu de Sud ailleurs en France, c'est également possible grâce à Chronofresh!`
            },
            {
                question: 'Vos produits frais, ils restent vraiment frais ?',
                answer: `Nous travaillons sans stock puisque nous attendons la validation de vos commandes avant d'acheter les ingrédients qui composeront vos CABADICI.
                Cela nous permet d'éviter le gaspillage mais également de vous proposer des produits d'une fraîcheur incomparable en commandant à nos fournisseurs la semaine d'avant.
                Les poissons que nous vous livrons le vendredi seront majoritairement pêchés sur les côtes du Grau du roi le mercredi!
                Par ailleurs, nous maîtrisons la chaîne du froid de nos approvisionnements (camion et caissons réfrigérés), de notre entreposage (chambres froides séparées pour les différentes températures nécessaires) et de votre livraison (pochettes isothermes complétées par des plaques eutectiques (gel glacé)).
                Nous travaillons à ce que les produits que nous vous livrons soient toujours irréprochables aussi bien sur la qualité, la provenance ou la fraîcheur.
                Si malgré nos efforts, vous constatez un quelconque dysfonctionnement dans votre CABADICI, n'hésitez surtout pas à nous le signaler le plus rapidement possible afin que l'on puisse remplacer le produit, nous excuser à plates coutures et rectifier immédiatement le souci.`
            },
            {
                question: 'Je suis abonné, je n\'ai pas eu le temps de suspendre ma prochaine livraison, mais je ne serais pas à la maison pour la réceptionner ! Je peux faire comment ?',
                answer: `A cas exceptionnel, réponse exceptionnelle, nous ferons tout pour vous arranger!
                Etant donné que de notre côté les commandes seront passées auprès de nos fournisseurs et que votre compte aura été débité, un CABADICI sera en route pour vous.
                Si vous ne pouvez pas le réceptionner, appelez nous vite!
                Soit nous essaierons de vous livrer ailleurs (chez un proche qui vous gardera tout cela au frais en vous attendant) ou à un autre moment (on se donnerait rdv pendant le weekend ou même le lundi, on vous gardera tout au frais en attendant), soit nous vous proposerons d'offrir votre CABADICI à quelqu'un de votre entourage!`
            },
            {
                question: 'La livraison en point partenaire, c\'est simple ?',
                answer: `Très simple !
                En choisissant de vous faire livrer chez un de nos partenaires, vous cumulez deux avantages non négligeables :
                1- vous bénéficiez d'une livraison gratuite au plus près de chez vous ou de votre lieu de travail
                2- vous avez une grande flexibilité dans les horaires puisque c'est vous qui décidez du moment qui vous convient le mieux pour récupérer votre CABADICI
                (nos partenaires conservent votre CABADICI au sec et vos aliments frais vous attendent dans leur pochette isotherme avec la petite plaquette de gel glacé)
                Dernier avantage sympathique : vous découvrez ou re-découvrez nos partenaires et leurs produits qui pourront compléter à merveille votre CABADICI !
                (du vin, des fleurs,...)`
            },
            {
                question: 'Faut-il que j\'imprime un justificatif de paiement pour retirer mon CABADICI en point partenaire ?',
                answer: `Non, il ne sera pas nécessaire de fournir de justificatif! Pensez simplement à présenter une pièce d'identité.`
            },
        ]
    },
    {
        section: 'LES COMMANDES',
        contents: [
            {
                question: 'C\'est long 7 jours avant de recevoir mon CABADICI, pourquoi est-ce que je ne pourrais pas le recevoir avant ?',
                answer: `Pour éviter les stocks et le gaspillage, nous avons choisi de ne passer nos commandes qu'une fois la votre validée.
                Le délai de 7 jours est le minimum que nous puissions garantir pour le moment pour pouvoir récupérer auprès de nos producteurs et partenaires les justes quantités, pour confectionner vos CABADICI et pour organiser les livraisons.
                C'est une habitude à prendre, les courses sont faites pour vous mais vous anticipez un petit peu vos menus de la semaine suivante!`
            },
            {
                question: 'Y a-t-il une consigne sur le cabas ou sur la pochette isotherme ?',
                answer: `Notre joli cabas, ainsi que les pochettes isothermes et plaque eutectiques (gel glacé) vous sont totalement offerts, et à chaque fois.
                Il nous semblait incongru de vous demander de régler une consigne car il nous était difficile d'imaginer ne pas vous la rendre si par malheur vous aviez oublié de nous rendre le cabas la fois suivante.
                Et puis, c'est avec plaisir qu'on croisera nos jolis cabas à la mer si vous vous balladez avec!
                Cela dit, vous finirez sûrement par en avoir un peu trop! Et puis stocker des pochettes iso ou des petites pochettes de gel, cela va vite vous prendre de la place au congélateur.
                Alors nous vous proposons de nous rendre tout cela quand et si vous le souhaitez.
                Lors de votre prochaine livraison, n'hésitez pas à nous ramener vos cabas, pochettes et plaquettes de gel, nous les réutiliserons.
                En échange, nous serons ravies de vous faire découvrir une des spécialités d'un de nos fournisseurs (petit pot de tapenade, de miel, de confit d'oignons etc etc), en cadeau!`
            },
            {
                question: 'Je voudrais recevoir un CABADICI avec des recettes et des ingrédients de que je choisis moi-même. Est-ce possible ?',
                answer: `Cette option de CABADICI personnalisé vous est en effet proposée!
                Il suffit de nous envoyer un petit mail à contact@cabadici.fr en nous précisant ce qui vous ferait plaisir.
                Nous échangerons avec vous pour pouvoir vous donner un devis le plus pertinent possible en fonction des ingrédients, du nombre de personnes...
                Et nous nous occuperons ensuite des courses pour vous!`
            },
        ]
    },
    {
        section: 'LE PAIEMENT',
        contents: [
            {
                question: 'Dois-je fournir une autorisation de prélèvement si je m\'abonne ?',
                answer: `Lorsque vous validez votre première commande, le paiement se fait par débit immédiat sur la carte bancaire dont vous aurez entré les coordonnées, comme pour n'importe quel achat que vous effectuez sur internet.
                Les semaines qui suivent, nous débiterons à nouveau votre carte bancaire, le dimanche à minuit (sauf si vous suspendez ou résiliez votre abonnement bien-sûr).
                Comme il ne s'agit pas d'un prélèvement directement sur votre compte bancaire, mais d'un paiement récurrent par carte bancaire, vous n'avez besoin ni d'entrer à nouveau vos codes bancaires, ni de nous transmettre d'autorisation spécifique.
                Si vous résiliez votre abonnement, et souhaitez ensuite le réactiver, alors le règlement aura lieu au moment où vous validez votre commande.`
            },
            {
                question: 'C\'est un paiement sécurisé ?',
                answer: `Nous utilisons la plateforme sécurisée STRIPE qui est, sur le marché, l'interface de gestion des paiements en ligne la plus fiable.
                Toutes les données bancaires qui lui sont transmises sont cryptées via un protocole spécifique hautement sécurisé (TLS).
                Ainsi, même si vos coordonnées bancaires sont conservées pour effectuer un paiment régulier, elles sont totalement inviolables et invisibles par nos soins, ou par d'autre utilisateurs.
                Si vous souhaitez en savoir plus, rendez-vous sur https://stripe.com/fr/.`
            },
            {
                question: 'Je souhaiterai changer de carte bancaire pour régler mes prochains CABADICI, comment puis-je faire ?',
                answer: `Pour modifier vos coordonnées de paiement, connectez-vous dans votre espace personnel, rubrique ""mon compte"".
                Vous pourrez alors enregistrer votre nouvelle carte de crédit, qui remplacera la précédente.`
            },
            {
                question: 'Puis-je avoir une facture ?',
                answer: `Bien sûr! Envoyez nous simplement un petit mail à contact@cabadici.fr, nous vous la ferons parvenir par retour de mail dans les plus brefs délais.`
            },
            {
                question: 'Peut on vous régler à réception ? Par chèque ou en espèces ?',
                answer: `Le règlement s’effectue uniquement par carte bancaire pour pouvoir valider votre commande définitivement.
                Etant donné qu'il sagit de produits frais et donc perissables, nous ne pouvons envisager le paiement lors de la livraison, le risque d'annulation à la dernière minute serait plus important et donc le gaspillage alimentaire également.
                Comme pour une commande quelconque sur internet, votre commande ne peut être validée qu'une fois réglée en ligne.`
            },
        ]
    },
    {
        section: 'LES RECETTES',
        contents: [
            {
                question: 'Je ne suis pas du tout doué en cuisine! Vais-je réussir à suivre vos recettes ? Ai-je besoin de matériel particulier ?',
                answer: `Toutes nos recettes sont élaborées avec pour objectifs:
                - d'être facilement et rapidement réalisables,
                - de plaire au plus grand nombre sans tomber dans une cuisine trop basique,
                - de varier votre quotidien avec des idées nouvelles ou des produits originaux.
                Chaque recette est très clairement expliquée, nous n'employons pas de termes culinaires difficiles à comprendre, et aucun équipement spécifique n'est nécessaire (mis à part un four, des poêles et casseroles, des saladiers et parfois un mixeur ou blender ou une râpe).
                Nous n'avons absolument aucun doute quant à votre réussite!`
            },
            {
                question: 'Combien de temps cela va-t-il me prendre de cuisiner ? Notamment pour les recettes longues ?',
                answer: `La majorité des recettes que nous vous proposons sont réalisables en 30 minutes environ, temps de cuisson compris.
                Pour les recettes longues, cela pourra aller parfois jusqu'à 1h en sachant que le temps de cuisine ne dépassera jamais 20 à 25 minutes.
                Le reste sera de la cuisson au four, ou en mijoté.
                En parallèle, nous vous le précisons dans chaque recette mais il se peut que les temps de cuisson varient parfois de quelques minutes entre notre recette et votre cuisine. En fonction de votre matériel, de la taille des morceaux que vous avez coupé ou de la puissance de votre four/plaque de cuisson, il vous appartiendra de prolonger parfois un tout petit peu le temps de cuisson pour arriver à la cuisson que vous aimez.
                De même, n'hésitez jamais à goûter et à ajuster les assaisonnements en fonction de vos habitudes et vos goûts!`
            },
            {
                question: 'Peut on choisir le nombre de personnes pour lesquelles on va cuisiner ?',
                answer: `Nos CABADICI sont prévus et tous les ingrédients sont calibrés pour pouvoir servir 4 portions adultes.
                Pour le moment, il n'est pas possible de modifier ce paramètre.
                L'avantage, c'est que vous pourrez toujours congeler une partie ou bien prévoir votre repas pour le lendemain midi!
                (aucun de nos ingrédients n'a été congelé auparavant, vous pouvez donc y aller les yeux fermés).
                Et s'il vous faut des quantités un peu plus importantes, n'hésitez pas à nous le faire savoir, nous chercherons un solution.`
            },
            {
                question: 'Peut on choisir les recettes à l\'avance ?',
                answer: `Vous pouvez visualiser nos recettes des 3 semaines à venir.
                Il sera tout de même important de les sélectionner chaque semaine, pour que nous soyons sûrs que vos choix n'ont pas été faits sans le vouloir.
                Une fois vos recettes de la semaine validées, vous recevrez un récapitulatif.
                Et si vous oubliez de vous connecter pour valider votre choix, nous vous le rappelerons par email!`
            },
            {
                question: 'Y a-t-il des oeufs ou du fromage dans les recettes VG ?',
                answer: `Nos recettes VG (végétariennes) sont des recettes qui ne comportent ni viande ni poisson.
                Le plus souvent, elles sont composées de légumes, féculents, légumineuses ou céréales, d'oeufs et/ou de fromage.
                Certains produits de la recette peuvent contenir des ingrédients ou traces possibles d'ingrédients non végétariens. Ces informations sont indiquées sur les emballages des produits concernés.`
            },
            {
                question: 'Je voudrais refaire une recette qui m\'a vraiment plu. Puis-je retrouver quelque part le déroulé et les ingrédients ?',
                answer: `Bien-sûr! Envoyez nous simplement un petit mail à contact@cabadici.fr, nous vous enverrons votre recette fétiche sans tarder!
                Et bientot, nous vous mettrons vos recettes en ligne sur votre espace personnel pour que vous puissiez les retrouver quand vous le souhaitez.`
            },
            {
                question: 'Les recettes changent souvent ?',
                answer: `Chaque semaine, nous vous proposons 4 recettes différentes.
                Qui ne reviendront pas, pour vous proposer des menus toujours plus variés!
                En complément, une cinquième recette reste disponible pour le mois entier.
                Ainsi, vous pouvez la recommander plusieurs fois si celle-ci vous a plu !!`
            },
            {
                question: 'Justement, je souhaiterais recevoir de quoi faire une recette CABADICI que j\'ai reçu il y a quelques temps. Est-ce possible ?',
                answer: `Bien sûr, aucun problème. Envoyez nous un petit mail à contact@cabadici.fr et nous nous ferons un plaisir de vous proposer une CABADICI personnalisé sur devis.
                Qu'il s'agisse d'une de nos recettes, ou même d'une recette que vous aimez et qui vous ""appartient"", nous pouvons vous livrer ce qui vous fait plaisir.
                Vous pouvez même personnaliser le nombre de personnes que vous souhaitez régaler, ou le style de repas que vous souhaitez cuisiner (un apéritif, un gouter, un brunch...)
                Chaque CABADICI personnalisé fera donc l'objet d'une tarification particulière que nous vous communiquerons par mail.`
            }
        ]
    },
    {
        section: 'LES INGREDIENTS',
        contents: [
            {
                question: 'Les ingrédients sont-ils bios ?',
                answer: `Une grande partie des ingrédients qui composent votre CABADICI sont bios.
                En effet nous avons à coeur de vous permettre de consommer responsable et/ou de faire du bien à votre santé.
                Cela dit, étant donné que notre idée principale est de promouvoir la production locale et les produits de notre région, nous donnerons parfois la priorité à un approvisionnement en circuit court.
                Tous nos fournisseurs partenaires, qu'ils soient bios, en conversion ou non bio, sont engagés avec nous dans une démarche de qualité éco-responsable.
                Nous privilégions bien sûr les labels (AOC, AOP...) et des produits avec du goût.
                La fraîcheur de chaque ingrédient sera également mise en grande priorité : poisson péché en Méditerranée l'avant-veille de votre livraison, fruits et légumes récoltés la semaine de la livraison...`
            },
            {
                question: 'D\'où viennent les ingrédients de mon CABADICI ?',
                answer: `Il est vrai que ""le local"" ça peut être vaste. Et puis, local par rapport à où ?
                Notre base secrète est au nord de Montpellier, nous nous attachons donc à nous approvisionner dans un environnement proche de cette zone.
                Nous rencontrons les producteurs au plus proche de chez nous, de chez vous. Mais notre région n'étant pas productrice de tout ce qui est nécessaire pour imaginer de bonnes recettes variées et originales, cela veut parfois dire que nous allons chercher nos produits dans les départements voisins.
                Pour les principaux, ce qu'il faut retenir :
                - les fruits, les légumes, les oeufs et le fromage seront quasiment toujours bios et/ou produits dans l'Hérault ou les départements limitrophes
                - le boeuf est un boeuf pur Aubrac
                - le veau, le porc et l'agneau sont de Lozère ou d'Aveyron
                - le poisson frais est péché au Grau du Roi
                - la truite est bio et élevée dans les Cévennes
                - le thon est mis en boîte à Marseille
                - le riz est bien-sûr de Camargue (Gard), ainsi que la plupart des céréales et légumineuses
                - les pâtes sont fabriquées dans le Gard ou en Aveyron`
            },
            {
                question: 'Certains ingrédients ont-ils déjà été congelés ?',
                answer: `Jamais!
                Aucun des ingrédients que vous trouverez dans votre CABADICI n'est passé par un congélateur.`
            },
            {
                question: 'Combien de temps puis-je garder les ingrédients une fois reçus ?',
                answer: `Nous vous recommandons de cuisiner vos ingrédients dès que possible, profitez du weekend pour prendre de l'avance sur les repas de la semaine d'après!
                Cuisinez toujours en premier les recettes avec du poisson frais, puis juste après, celles avec de la viande.
                Et gardez bien-sûr tout au réfrigérateur, même les légumes, maximum 5 jours (sauf lorsqu'une Date Limite de Consommation différente est précisée sur le produit).`
            }
        ]
    },
    {
        section: 'SAV - PETITS SOUCIS	',
        contents: [
            {
                question: 'Par rapport à la liste d\'ingrédients présente sur la recette, il manque un produit dans mon CABADICI...que puis-je faire ?',
                answer: `Nous confectionnons tous les CABADICI nous-même en prenant le plus grand soin pour ne rien oublier.
                Si une erreur s'est glissée dans votre CABADICI et que nous vous avons oublié un ingrédient, que les quantités d'un ingrédient ne sont pas les bonnes ou qu'un ingrédient a été remplacé par un autre, nous en sommes profondément désolées et vous prions d'accepter nos plus plates excuses!
                Pour remédier à cela, prévenez nous le plus tôt possible en nous envoyant un texto ou un mail, ou bien encore en appelant le numéro d'urgence 07 56 87 34 12.
                Nous ferons notre maximum pour remédier à cet incident.`
            },
            {
                question: 'J\'ai reçu du persil mais sur ma recette il est noté de la coriandre. C\'est normal ?',
                answer: `Il peut arriver que nos producteurs rencontrent des aléas dans leurs récoltes. Ainsi, parfois, certaines herbes ou même certaines variétés de poisson pourront être remplacées par d'autres.
                Aucune inquiétude, les saveurs de votre recette resteront aussi goûteuses!
                Il s'agit simplement d'un petit ajustement que nous ne pouvons malheureusement pas maîtriser, mais qui participe à l'idée d'une consommation locale, de saison et en direct de nos producteurs!
                Et puis, mieux vaut un petit imprévu qu'un remplacement identique que nous serions contraints de trouver ailleurs...(plus loin, chez un fournisseur que nous ne connaissons pas bien...).
                Cela dit, nous nous efforçons toujours de vous prévénir lorsque nous remplaçons un produit, avec un petit mot dans votre CABADICI par exemple.`
            },
            {
                question: 'Je viens d\'ouvrir mon CABADICI et certains ingrédients ne me semblent pas très en forme...je fais quoi ?',
                answer: `Nous essayons d'emballer au mieux chacun des ingrédients de votre CABADICI pour éviter que vos aliments ne s'abiment ou perdent en fraîcheur.
                Cela dit, si vous constatez que l'un de vos ingrédients est endommagé ou ne semble pas à la bonne température, signalez nous le au plus vite.
                (par téléphone ou par texto pour une réponse au plus rapide : 07 56 87 34 12)
                Si vous avez été livré par notre prestataire CHRONOFRESH, et que le carton vous semble vraiment abimé, n'hésitez pas à refuser la livraison auprès du transporteur.
                Il faudra alors nous en informer dès que possible, afin que nous puissions vous rembourser.`
            },
            {
                question: 'J\'attends ma livraison, mais je ne vois rien venir...que puis-je faire ?',
                answer: `Si vous vous inquietez d'un retard, n'hésitez pas à nous appeler ou à nous envoyer un petit texto.
                Le numéro d'urgence : 07 56 87 34 12.
                Il se peut qu'il y ait eu un contretemps sur la route ou une incompréhension quelque part.
                Dans tous les cas, n'hésitez pas à nous contacter pour que nous puissons vous rassurer le plus vite possible.`
            },
            {
                question: 'J\'ai raté ma livraison, je n\'étais pas à la maison...comment puis-je faire ?',
                answer: `Nous avons sûrement essayé de vous appeler plusieurs fois, n'hésitez pas à nous rappeler! (07 56 87 34 12)
                Si nous sommes toujours dans votre quartier, nous allons repasser. Si nous sommes repartis (ou que votre livraison était assurée par notre prestataire CHRONOFRESH) nous reprogrammerons ensemble votre livraison.
                Si nous n'arrivons à trouver ensemble un autre moment pour vous relivrer, nous ne pourrons malheureusement pas vous rembourser...`
            }
        ]
    },
    {
        section: 'L\'EPICERIE',
        contents: [
            {
                question: 'Comment fonctionne l\'épicerie ?',
                answer: `Nous vous proposons deux types de produits dans notre petite épicerie :
                1- les mini recettes : elles fonctionnent exactement comme les CABADICI, mais sur des petits compléments à votre plat. Par exemple si vous souhaitez recevoir tous les ingrédients pour cuisiner vous même un guacamole ou une tapenade, ou même un gateau.
                2- les prêts à manger : il s'agit de produits tout prêts (par exemple des yaourts ou un petit pot de confit d'oignons) ou un assortiment de produits (par exemple un panier de fruits frais ou un assortiment de charcuteries ou de fromages) qui viennent eux aussi compléter votre repas.`
            },
            {
                question: 'Puis-je acheter des produits depuis l\'épicerie, même si j\'ai suspendu ma livraison ?',
                answer: `Les produits que vous retrouvez dans l'épicerie vous sont proposés en complément de votre CABADICI.
                Malheureusement, pour le moment, nous ne pouvons nous permettre de vous livrer uniquement ces compléments.
                Il vous faudra donc reprendre votre abonnement si vous souhaitez acheter un produit de notre épicerie.
                Pour ce faire, rdv dans votre espace personnel, choisissez les recettes de votre prochaine livraison puis sélectionnez les produits que vous souhaitez recevoir.
                Le montant qui sera débité sur votre carte de paiement sera automatiquement ajusté.`
            }
        ]
    }
];
