import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

import withTheme from '../theme';
import { Layout } from '../components1/layout';
import LinedTitle from '../components1/LinedTitle';
import {Title, Text} from '../components1/Texts';
import FlexDiv from '../components1/FlexDiv';
import Section from '../components1/Section';
import FaqTexts from '../components1/FaqTexts';

import {faqData} from '../data/faq';

const LinedTitleResponsive = styled(LinedTitle)`
    padding-top: 40px;
    @media only screen and (max-width: 599px) {
        display: none !important;
    }
`;

const TitleResponsive = styled(Title)`
    padding-top: 15px;
    font-size: 1.4rem;
    /* margin-left: 40px; */
    /* padding-bottom: 10px; */
    /* white-space: nowrap; */

    @media only screen and (min-width: 599px) {
        display: none !important;
    }
`;

const FaqSections = ({ faqSection }) => {
    return (
        <>
            <Title
                style={{
                    padding: '20px 5px 5px 0',
                    fontSize: '1.675rem'
                }}
                css={`
                    color: ${props => props.theme.color.rougeFort};
                    @media only screen and (max-width: 599px) {
                        font-size: 1.2rem !important;
                    }
                `}
            >
                {faqSection.section}
            </Title>
            {
                faqSection.contents.map((faq, index) => {
                    return (
                        <FaqTexts
                            key={index.toString()}
                            faq={faq}
                        />
                    )
                })
            }
        </>
    )
};

const IndexPage = withTheme(({ theme, data }) => (
    <Layout pageName="faq">
        {
            ({ openConnectForm }) => (
                <>
                    <LinedTitleResponsive
                        borderColor={theme.color.rougeFort}
                        borderWidth="1.5em"
                        style={{
                            paddingLeft: 0,
                            // marginTop: 80
                        }}
                    >
                        <Title>Questions les plus fréquentes</Title>
                    </LinedTitleResponsive>
                    <TitleResponsive
                        style={{
                            textAlign: 'center'
                        }}
                    >
                            Questions les plus fréquentes
                    </TitleResponsive>
                    <Section
                        style={{
                            paddingTop: 0,
                            paddingBottom: 30
                        }}
                    >
                        {
                            faqData.map((faqSection, index) => {
                                return (
                                    <FaqSections
                                        key={index.toString()}
                                        faqSection={faqSection}
                                    />
                                )
                            })
                        }
                    </Section>
                    <FlexDiv
                        style={{
                            justifyContent: 'center'
                        }}
                        css={`
                            background-color: ${props => props.theme.color.beigeFaible};
                        `}
                    >
                        <BackgroundImage
                            fluid={data.backgroundParenthesesNoires.childImageSharp.fluid}
                            style={{
                                // default values:
                                // backgroundRepeat: 'no-repeat',
                                // backgroundSize: 'cover',
                                // backgroundPosition: 'center',

                                backgroundSize: '100% 100%',
                                objectFit: 'contain',

                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',

                                minWidth: '20rem',
                                minHeight: '10rem',
                                margin: 10,
                                padding: 20,
                            }}
                            fadeIn={false}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    min-width: 8rem !important;
                                    min-height: 4rem !important;
                                }
                            `}
                        >
                            <Text style={{textAlign: 'center'}}>
                                Pour tout autre question,
                                <br />
                                {'n\'hésitez pas à nous envoyer un mail à'}
                                <br />
                                <a
                                    href="mailto:contact@cabadici.fr"
                                    style={{
                                        color: '#000',
                                        // textDecoration: 'none'
                                    }}
                                >contact@cabadici.fr</a>
                            </Text>
                        </BackgroundImage>
                    </FlexDiv>
                </>
            )
        }
    </Layout>
));

IndexPage.propTypes = {
    theme: PropTypes.shape().isRequired
};

export default IndexPage;

export const pageQuery = graphql`
    query {
        backgroundParenthesesNoires: file(relativePath: { eq: "backgrounds/parentheses-noires-rectangle.png" }) {
            childImageSharp {
                fluid(maxHeight: 100, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
