import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Text } from './Texts';

class faqTexts extends Component {
    static propTypes = {
        faq: PropTypes.shape({
            question: PropTypes.string.isRequired,
            answer: PropTypes.string.isRequired
        }).isRequired
    };

    state = {
        isOpen: false
    };

    onClick = () => {
        this.setState(({isOpen}) => ({ isOpen: !isOpen }));
    };

    render() {
        const { faq: { question, answer }} = this.props;
        const { isOpen } = this.state;

        return (
            <>
            <Text
                style={{
                    fontWeight: 600,
                    fontSize: 18,
                    paddingTop: 20,
                    paddingBottom: 0,
                }}
                css={`
                    :hover{
                        cursor: pointer;
                    }
                `}
                onClick={this.onClick}
            >
                {question}
            </Text>
            {
                isOpen && (
                    <Text
                        style={{
                            textAlign: 'justify'
                        }}
                    >
                        {answer}
                    </Text>
                )
            }
            </>
        )
    }
};

export default faqTexts;
